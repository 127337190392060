
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ol, ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  

@tailwind base;
@tailwind components;
@tailwind utilities;



