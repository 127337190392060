.package_img {
    position: relative; 
    margin-right: 10px; 
    transition: top 0.3s ease-in-out; 
  }
  
.package_img.fixed {
    position: sticky; 
    top: 80px; 
    z-index: 10; 
  }