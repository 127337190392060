.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff38;
  z-index: 30 !important;
  position: absolute;
  transition: background-color 0.3s ease-in-out;
  color: rgb(0, 0, 0) !important;
  padding-left: 20px;
  padding-right: 20px;
}

.navbar.fixed {
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  position: fixed;
  box-shadow: inset;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 3px solid rgb(18, 17, 17) !important;
  color: black !important;
}


@media only screen and (max-width: 760px) {
  .navbar {
    /* padding-left: 5px;
    padding-right: 5px; */
    justify-content: baseline;
  }
}
