.product_img {
  position: relative; /* Default position */
  margin-right: 10px; /* Space on the right */
  transition: top 0.3s ease-in-out; /* Smooth transition for sticky effect */
}

.product_img.fixed {
  position: sticky; /* Change to sticky when fixed */
  top: 80px; /* Adjust to your desired offset */
  z-index: 10; /* Ensure it stays above other elements */
}



.slick-slide {
  /* padding:5px; */
  text-align:center;
  }

.slick-prev {
  /* font-size: 30px; */
  background-color: #b0b0b0d4 !important;
  left: 10px !important;
  width: 40px !important;
  height: 40px !important;
  /* color: black !important; */
  z-index: 10 !important;
  border-radius: 50% !important; 
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.slick-prev::before {
  content: url("../image/icons8-arrow-48pre.png") !important;
  /* color: rgb(0, 0, 0) !important; */
  font-size: 25px !important;
  /* font-weight: 600 !important; */
}

.slick-next {
  /* font-size: 30px; */
  background-color: #d3d3d3  !important;
  right: 10px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: rgb(255, 255, 255) !important;
  z-index: 10 !important;
  border-radius: 50% !important; 
 
}

.slick-next::before {
  content: url("../image/icons8-arrow-48.png") !important;
  /* color: rgb(0, 0, 0) !important; */
  font-size: 25px !important;
  font-weight: 600 !important;
  
}


@media only screen and (max-width: 760px) {
  .slick-prev {
    /* font-size: 30px; */
    background-color: #ffffff00 !important;
    left: 10px !important;
    width: 30px !important;
    height: 30px !important;
    /* color: black !important; */
    z-index: 10 !important;
    border-radius: 50% !important; 
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .slick-prev::before {
    content: url("../image/icons8-arrow-48pre.png") !important;
    /* color: rgb(0, 0, 0) !important; */
    font-size: 15px !important;
    /* font-weight: 600 !important; */
  }
  
  .slick-next {
    /* font-size: 30px; */
    background-color: #ffffff00  !important;
    right: 10px !important;
    width: 30px !important;
    height: 30px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    /* color: rgb(255, 255, 255) !important; */
    z-index: 10 !important;
    border-radius: 50% !important; 
    padding: 2 !important;
   
  }
  
  .slick-next::before {
    content: url("../image/icons8-arrow-48.png") !important;
    /* color: rgb(0, 0, 0) !important; */
    font-size: 10px !important;
    font-weight: 600 !important;
    
  }
}